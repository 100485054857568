<template>
  <b-card>
    <header-table title="إدارة مستخدمين المنارة" @create="onAdd()" />
    <div class="border pt-1 px-1 rounded-lg mb-1 shadow-sm">
      <b-row>
        <b-col cols="5">
          <form-input
            label=""
            v-model="filter"
            placeholder="ابحث هنا عن اسم المستخدم..."
            class="w-50"
          />
        </b-col>
      </b-row>
    </div>
    <data-table ref="estatesTable" :fields="fields" :filter="filter" ep="/users">
      <template #cell(gender)="row">
        {{ row.item.gender == "M" ? "ذكر" : "انثى" }}
      </template>

      <template #cell(actions)="row">
        <actions-table @delete="onDelete(row.item)" @update="onUpdateClicked(row.item)">
          <template #extend-dropdown-item>
            <b-dropdown-item @click="onAddClickedPassword(row.item)" v-can="{ slug: $route.meta.slug, action: 'O' }">
              <feather-icon icon="KeyIcon" />
              <span class="align-middle ml-50">تغيير كلمة السر</span>
            </b-dropdown-item>
          </template>
        </actions-table>
      </template>
    </data-table>

    <form-modal
      ref="estateModalUser"
      :formSchema="estatesFormSchema"
      title="إضافة"
      v-model="activModel"
      :loading="loading"
      @confirm="onModalConfirmed"
    >
    </form-modal>

    <form-modal
      ref="passwordModalUser"
      :formSchema="passwordFormSchema"
      title="تغيير كلمة السر"
      :loading="loading"
      v-model="passwordactivModel"
      @confirm="onModalConfirmedPassword"
    >
    </form-modal>
  </b-card>
</template>
<script>
import {
  BCard,
  BButton,
  BRow,
  BCol,
  BCollapse,
  BImg,
  BMediaBody,
  BMedia,
  BMediaAside,
  BAvatar,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";
import { BCarousel, BCarouselSlide } from "bootstrap-vue";
import DataTable from "@/components/data-table/index.vue";
import FormModal from "@/components/FormModal.vue";
import HeaderTable from "@/components/data-table/components/header-table.vue";
import ActionsTable from "@/components/data-table/components/actions-table.vue";
import FormInput from "@/components/form-input/index.vue";
import FormFile from "@/components/form-file/index.vue";
import dataSchema from "./mixins";

export default {
  components: {
    HeaderTable,
    ActionsTable,
    BCard,
    DataTable,
    BButton,
    FormInput,
    BRow,
    BCol,
    FormModal,
    FormFile,
    BCollapse,
    BImg,
    BCarousel,
    BCarouselSlide,
    BMediaAside,
    BMediaBody,
    BMedia,
    BAvatar,
    BDropdown,
    BDropdownItem,
  },

  data: () => {
    return {
      filter: "",
      imageSlide: [],
      staticImages: [],
      activModel: false,
      passwordactivModel: false,
      fields: [
        { key: "name", label: "الاسم", sortable: true },
        { key: "username", label: "اسم المستخدم" },
        { key: "mobile", label: "رقم الموبايل" },
        { key: "email", label: "البريد الإلكتروني" },
        { key: "dob", label: "تاريخ الميلاد" },
        { key: "gender", label: "الجنس" },
        { key: "role.name", label: "الصلاحية" },
        { key: "actions", label: "" },
      ],
      estatesFormSchema: {
        name: {
          component: "FormInput",
          key: "name",
          attrs: {
            id: "name",
            label: "الاسم",
            rules: "required",
          },
        },
        username: {
          component: "FormInput",
          key: "username",
          attrs: {
            label: "اسم المستخدم",
            rules: "required",
          },
        },
        mobile: {
          component: "FormMask",
          key: "mobile",
          attrs: {
            options: "phone",
            rules: "required|min:10",
            label: "رقم الموبايل",
            dir: "ltr",
          },
        },
        email: {
          component: "FormInput",
          key: "email",
          attrs: {
            id: "email",
            label: "البريد الإلكتروني",
            rules: "required|email",
          },
        },
        dob: {
          component: "FormDatepicker",
          key: "dob",
          attrs: {
            id: "date_user",
            label: "تاريخ الميلاد",
            rules: "required",
            placeholder: "YY-MM-DD",
          },
        },
        gender: {
          component: "FormSelect",
          key: "gender",
          attrs: {
            id: "gender",
            label: "الجنس",
            rules: "required",
            reduce: (el) => el.value,
            options: [
              { label: "ذكر", value: "M" },
              { label: "انثى", value: "F" },
            ],
          },
        },

        role_id: {
          component: "FormSelect",
          key: "role_id",
          attrs: {
            reduce: (x) => x.id,
            getOptionLabel: (x) => x.name,
            id: "role_id",
            label: "الصلاحيت",
            ep: "select/user-roles",
            rules: "required",
          },
        },
        password: {
          component: "GeneratePassword",
          key: "password",
          attrs: {
            id: "password",
            label: "كلمة السر ",
            rules: "required",
          },
        },
      },
      passwordFormSchema: {
        old_password: {
          component: "FormMask",
          key: "o_password",
          attrs: {
            id: "o_password",
            label: " كلمة السر الخاصة بك",
            // type: "password",
            isPassword: true,
            rules: "required",
            options: "password",
          },
        },
        new_password: {
          component: "FormMask",
          key: "new_password",
          attrs: {
            id: "new_password",
            label: " كلمة السر الجديدة ",
            type: "password",
            rules: "required",
            options: "password",
            isPassword: true,
          },
        },
        new_password_confirmation: {
          component: "FormMask",
          key: "new_password_confirmation",
          attrs: {
            id: "new_password_confirmation",
            label: "تأكيد كلمة السر ",
            rules: "required",
            type: "password",
            options: "password",

            isPassword: true,
          },
        },
      },
    };
  },
  mixins: [dataSchema],
  methods: {
    ...mapActions("settings/manageBeneficiary", [
      "updateUser",
      "createUser",
      "deleteUser",
      "changePassword",
    ]),

    onAdd() {
      this.$refs.estateModalUser.init({});
      this.activModel = true;
    },

    onUpdateClicked(form) {
      var toUpdate = {
        id: form.id,
        user_id: form.id,
        name: form.name,
        username: form.username,
        email: form.email,
        mobile: form.mobile,
        role_id: form.role.id,
        phone_number: form.phone_number,
        dob: form.dob,
        gender: form.gender,
      };
      const selectedPassword = ["password"];
      const filteredUsers = Object.keys(this.estatesFormSchema)
        .filter((key) => !selectedPassword.includes(key))
        .reduce((obj, key) => {
          obj[key] = this.estatesFormSchema[key];
          return obj;
        }, {});

      this.estatesFormSchema = filteredUsers;
      this.$refs.estateModalUser.init(toUpdate);
      this.activModel = true;
    },

    onDelete(item) {
      // console.log(id)
      this.$bvModal
        .msgBoxConfirm("هل أنت متأكد من حذف النشاط", {
          title: "تأكيد حذف النشاط",
          size: "sm",
          okVariant: "danger",
          okTitle: "تأكيد",
          cancelTitle: "إلغاء",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value)
            this.deleteHalls({ id: item.id }).then(() => {
              this.$refs.estatesTable.refreshTable();
            });
        });
    },

    onModalConfirmed(form) {
      if (form.id) {
        this.updateUser({ id: form.id, data: form }).then(() => {
          this.$refs.estatesTable.refreshTable();
          this.activModel = false;
        });
      } else
        this.createUser({ data: form }).then(() => {
          this.$refs.estatesTable.refreshTable();
          this.activModel = false;
        });
    },

    onAddClickedPassword(form) {
      var toUpdate = {
        id: form.id,
      };
      this.$refs.passwordModalUser.init(toUpdate);
      this.activModel = false;
      this.passwordactivModel = true;
    },
    onModalConfirmedPassword(form) {
      const toUpdate = {
        new_password: form.new_password,
        new_password_confirmation: form.new_password_confirmation,
        old_password: form.old_password,
      };
      this.changePassword({ id: form.id, fd: toUpdate }).then(() => {
        this.$refs.estatesTable.refreshTable();
        this.passwordactivModel = false;
      });
    },
  },

  computed: {
    ...mapGetters("settings/manageBeneficiary", ["loading"]),
    ...mapGetters("publicPages/screenTV", ["events", "hallOccupancy", "QueueTv","loadingQ"])
  },
};
</script>
